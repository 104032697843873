import React from "react";
import styled from "styled-components";
import { companyData } from "../../constant/constant";

const CompanySection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  color: #e0e0e0;
  min-height: 100vh;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */

  padding: 2rem;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 2rem;
  color: #ffffff;
  background: linear-gradient(90deg, #ff8660, #9a33ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: justify;
`;

const IndustryHeading = styled.h1`
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #ffffff;
  padding: 1rem;
  border-radius: 10px 10px 0 0;
`;

const TableContainer = styled.div`
  width: 80%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const TableHeader = styled.th`
  background-color: #2a2a2a;
  color: #ffffff;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 2px solid #444;
  cursor: pointer;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #2a2a2a;
  }

  &:nth-child(odd) {
    background-color: #1e1e1e;
  }

  &:hover {
    background-color: #333;
  }
`;

const TableCell = styled.td`
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #444;
  font-family: "Poppins", sans-serif;
  color: #e0e0e0;
`;

const Company = () => {
  // Separate companies by industry for display
  const industries = {};
  companyData.forEach((company) => {
    if (!industries[company.industry]) {
      industries[company.industry] = [];
    }
    industries[company.industry].push(company);
  });

  return (
    <CompanySection>
      <Heading>Top Companies in Malaysia for Fresh Graduates 2024</Heading>
      <Description>
        Discover exciting career prospects with Malaysia's top companies,
        renowned for their commitment to nurturing fresh talent and offering
        diverse entry-level roles across multiple industries. Whether you're
        passionate about technology, finance, healthcare, or beyond, this blog
        provides invaluable insights into how these organizations support and
        empower young professionals in navigating their first steps into the
        dynamic Malaysian job market. Gain a comprehensive overview of each
        company's culture, growth opportunities, and the unique ways they foster
        professional development, helping you make informed decisions as you
        embark on your career journey.
      </Description>
      <Description>TO BE UPDATED</Description>
      <TableContainer>
        {Object.keys(industries).map((industry, index) => (
          <div key={index}>
            <IndustryHeading>{industry}</IndustryHeading>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Index</TableHeader>
                  <TableHeader>Company Name</TableHeader>
                  <TableHeader>Type</TableHeader>
                </tr>
              </thead>
              <tbody>
                {industries[industry].map((company, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.type}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </div>
        ))}
      </TableContainer>
    </CompanySection>
  );
};

export default Company;
