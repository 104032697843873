import React, { useRef, useState, useEffect } from "react";
import { certsData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";
import { Blurhash } from "react-blurhash"; // Import Blurhash component

const Certs = () => {
  const [showAll, setShowAll] = useState(false);
  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef, showAll);
  const sectionRef = useRef(null); // Ref for the section
  const [imageLoaded, setImageLoaded] = useState(
    new Array(certsData.length).fill(false)
  );

  const handleToggle = () => {
    setShowAll(!showAll);
    if (showAll) {
      // Use requestAnimationFrame to ensure smooth scrolling on iOS
      requestAnimationFrame(() => {
        // Force a reflow to update layout
        sectionRef.current.style.position = "relative";
        sectionRef.current.style.position = "";
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  useEffect(() => {
    certsData.forEach((cert, index) => {
      const img = new Image();
      img.src = cert.image;
      img.onload = () =>
        setImageLoaded((prevState) => {
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
    });
  }, []);

  const displayedCerts = showAll ? certsData : certsData.slice(0, 3);

  const handleCardClick = (cert) => {
    if (cert.link) {
      window.open(cert.link, "_blank"); // Open the link in a new tab
    }
  };

  return (
    <section id="certs" className="p-8 font-suse" ref={sectionRef}>
      <div className="flex flex-col items-center justify-center">
        <h2
          ref={(el) => {
            cardsRef.current[0] = el;
          }}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px] zoom"
        >
          Certificate
        </h2>
        <div className="flex flex-wrap justify-center gap-8 w-full max-w-[1200px]">
          {displayedCerts.map((certs, index) => (
            <div
              key={index}
              ref={(el) => (cardsRef.current[index + 1] = el)}
              onClick={() => handleCardClick(certs)}
              className="w-[300px] h-full m-4 flex flex-col bg-gray-800 rounded-lg overflow-hidden shadow-lg cursor-pointer hover:scale-105 fade"
            >
              <div className="relative w-full h-60">
                {!imageLoaded[index] && (
                  <Blurhash
                    hash="LKO2?U%2Tw=w]~-:i|D%{t7n}M{K"
                    width="100%"
                    height="100%"
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                )}
                <img
                  src={certs.image}
                  alt={certs.title}
                  className={`w-full h-full object-cover transition-opacity duration-300 ease-in-out ${
                    imageLoaded[index] ? "block" : "hidden"
                  }`}
                />
              </div>
              <div className="flex flex-col p-3 justify-center p-2">
                <h3 className="font-bold text-white text-xl text-center truncate">
                  {certs.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={handleToggle}
            className="bg-customBlue text-black font-bold px-4 py-2 rounded-md hover:bg-customBlue2 "
          >
            {showAll ? "See Less" : "See More"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Certs;
