import React, { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header
      id="header"
      className="relative bg-gray-900 text-white py-3 font-suse font-bold"
    >
      <div className="container mx-auto px-4 max-w-[1200px]">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div
            className="text-2xl font-bold flex items-center space-x-2 cursor-pointer "
            onClick={() => scrollToSection("home")}
          >
            <span className="text-3xl text-customBlue">×͜×</span>
            <span className="text-customBlue">¢нασѕ¢яєαтσя</span>
          </div>

          {/* Mobile Menu Button */}
          <button className="lg:hidden p-2 rounded-md" onClick={toggleMenu}>
            <svg
              className={`w-6 h-6 transform transition-transform duration-300 ${
                isMenuOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>

          {/* Navigation */}
          <nav className="hidden lg:flex space-x-6">
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("about")}
            >
              About Me
            </a>
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("education")}
            >
              Experience
            </a>
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("portfolio")}
            >
              Portfolio
            </a>

            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("contact")}
            >
              Contact Me
            </a>
          </nav>
        </div>

        {/* Dropdown Menu for Mobile */}
        <div
          className={`lg:hidden mt-2 ${
            isMenuOpen ? "block" : "hidden"
          } bg-transparent `}
        >
          <nav className="flex flex-col space-y-2 p-4">
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("about")}
            >
              About Me
            </a>
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("experience")}
            >
              Experience
            </a>
            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("portfolio")}
            >
              Portfolio
            </a>

            <a
              className="hover:text-customBlue transition cursor-pointer"
              onClick={() => scrollToSection("contact")}
            >
              Contact Me
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
