import React, { useRef, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const Contact = () => {
  const [subjectFocused, setSubjectFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const handleSubjectFocus = () => {
    setSubjectFocused(true);
  };

  const handleSubjectBlur = () => {
    setSubjectFocused(false);
  };

  const handleMessageFocus = () => {
    setMessageFocused(true);
  };

  const handleMessageBlur = () => {
    setMessageFocused(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const subject = subjectRef.current.value;
    const message = messageRef.current.value;

    const to = "adamadha3@gmail.com";
    const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=${message}`;

    window.open(gmailUrl, "_blank");
  };

  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef);

  return (
    <section id="contact" className="p-8 font-suse">
      <div className="flex flex-col items-center justify-center">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px] zoom"
        >
          Contact <b className=" text-customBlue">‎ Me</b>
        </h2>

        <div
          ref={(el) => (cardsRef.current[1] = el)}
          className="bg-gray-800 border-2 border-customBlue rounded-lg shadow-lg mb-8 w-[300px] p-4 text-white fade"
        >
          <div className="flex items-center">
            <div className="mr-4 bg-customBlue p-3 rounded-full">
              <FaEnvelope className="text-white text-2xl" />
            </div>
            <div>
              <div className="text-sm text-customBlue font-bold">My Email</div>
              <div className="text-base">adamadha3@gmail.com</div>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          ref={(el) => (cardsRef.current[2] = el)}
          className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-[600px] fade border-black"
        >
          <div className="mb-6 border-black">
            <label
              htmlFor="subject"
              className={"block font-semibold text-customBlue mb-2 font-suse "}
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              ref={subjectRef}
              placeholder="Your subject"
              required
              className="w-full p-3 text-white bg-transparent border-1 border-customBlue rounded-md focus:outline-none focus:ring-1  focus:ring-customBlue"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="message"
              className={"block font-semibold text-customBlue mb-2 font-suse"}
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="5"
              ref={messageRef}
              placeholder="Write your text..."
              required
              className="w-full p-3 text-white bg-transparent border-1 border-customBlue rounded-md focus:outline-none focus:ring-1 focus:ring-customBlue"
            ></textarea>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-customBlue hover:bg-customBlue2 hover:text-black text-black font-bold py-3 px-6 rounded-md transition-colors duration-300"
            >
              Submit
            </button>
          </div>
        </form>
        <div
          ref={(el) => (cardsRef.current[3] = el)}
          className="flex justify-center items-center pt-4 w-full max-w-[600px] fade"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.698838224691!2d101.6385908711865!3d2.902822860119551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb7f1264b07a9%3A0xcd2381f70d0659f2!2sPR1MA%20Two%20%40%20Laman%20View!5e0!3m2!1sen!2smy!4v1718708790862!5m2!1sen!2smy"
            width="100%"
            height="350"
            style={{
              border: "2px",
              borderRadius: "10px",
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
