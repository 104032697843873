import React, { useRef, useEffect } from "react";
import { FaApple, FaAndroid, FaFigma } from "react-icons/fa";

export const ModalView = ({ isOpen, onClose, selectedItem }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener to handle clicks outside the modal
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-3xl mx-4 md:mx-8 max-h-[90vh] overflow-auto no-scrollbar"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-red-400 font-bold hover:text-white transition duration-300"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="pt-12 p-8 flex flex-col">
          <img
            src={selectedItem.image}
            alt={selectedItem.title}
            className="w-full h-auto rounded-lg shadow-md mb-4"
          />
          <div>
            <h1 className="text-3xl font-bold text-white text-center mb-4">
              {selectedItem.title}
            </h1>

            <div className="flex flex-wrap gap-2 justify-center">
              {selectedItem.technology.map((tech, index) => (
                <div
                  key={index}
                  className="text-customBlue bg-customBlue3 rounded-2xl px-4 py-2"
                >
                  {tech}
                </div>
              ))}
            </div>
            <p className="text-white text-justify mb-4 mt-4">
              {selectedItem.description}
            </p>
            {selectedItem.link && (
              <div className="flex flex-wrap justify-center gap-2">
                {selectedItem.link[0].appstore && (
                  <a
                    href={selectedItem.link[0].appstore}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-md bg-white text-black font-bold py-3 px-6 rounded mx-2 my-2 cursor-pointer w-40 text-center"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <div className="mr-2">
                        <FaApple className="fa-beat" />
                      </div>
                      <div>App Store</div>
                    </div>
                  </a>
                )}

                {selectedItem.link[0].playstore && (
                  <a
                    href={selectedItem.link[0].playstore}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-md bg-transparent text-white font-bold py-3 px-6 border border-white rounded mx-2 my-2 cursor-pointer w-40 text-center"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <div className="mr-2">
                        <FaAndroid className="fa-beat" />
                      </div>
                      <div>Play Store</div>
                    </div>
                  </a>
                )}

                {selectedItem.link[0].figma && (
                  <a
                    href={selectedItem.link[0].figma}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-md bg-white text-black font-bold py-3 px-6 border border-white rounded mx-2 my-2 cursor-pointer w-40 text-center"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <div className="mr-2">
                        <FaFigma className="fa-beat" />
                      </div>
                      <div>Figma</div>
                    </div>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
