import React from "react";
import { FaLinkedin, FaGithub, FaResearchgate } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-2">
      <div className="container mx-auto px-4 max-w-[1200px]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Footer Logo or Title */}
          <div className="mb-2 md:mb-0 ">
            <h2 className="mb-2 md:mb-0 text-center text-2xl font-bold text-customBlue">
              Adam Kz
            </h2>
            <p className="text-gray-400">Developer & Designer</p>
          </div>

          {/* Footer Bottom */}
          <div className="mb-2 md:mb-0 md:mt-4 text-center">
            <p className="mb-2 text-gray-400">
              &copy; 2024 Adam Kz. All rights reserved.
            </p>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-4 mb-2 md:mb-0 md:mt-4">
            <a
              href="https://linkedin.com/in/adamadha3"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-blue-400 transition-colors duration-300"
            >
              <FaLinkedin size={24} />
            </a>
            <a
              href="https://www.researchgate.net/profile/Adam-Adha-2"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-green-400 transition-colors duration-300"
            >
              <FaResearchgate size={24} />
            </a>

            <a
              href="https://github.com/adamadha8"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-orange-400 transition-colors duration-300 "
            >
              <FaGithub size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
