import React, { useRef, useState } from "react";
import { skillsData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const Skill = () => {
  const [showAll, setShowAll] = useState(false);
  const sectionRef = useRef(null); // Ref for the section

  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef, showAll);

  const handleToggle = () => {
    setShowAll(!showAll);
    if (showAll) {
      // Use requestAnimationFrame to ensure smooth scrolling on iOS
      requestAnimationFrame(() => {
        // Force a reflow to update layout
        sectionRef.current.style.position = "relative";
        sectionRef.current.style.position = "";
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  const displayedSkills = showAll ? skillsData : skillsData.slice(0, 3);

  let itemIndex = 1;

  return (
    <section id="skills" className="p-8 text-white font-suse" ref={sectionRef}>
      <div className="flex items-center justify-center  ">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px] zoom"
        >
          Skill
        </h2>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-wrap justify-center gap-20 w-full max-w-[1200px] ">
          {displayedSkills.map((skillCategory, categoryIndex) => (
            <div
              key={categoryIndex}
              className="bg-gray-800 rounded-lg shadow-md overflow-hidden w-72 max-w-full"
            >
              <div className="bg-customBlue p-4 font-extrabold text-black text-center flex justify-center items-center gap-2">
                <skillCategory.icon className="w-5 h-5 " />
                <h1 className="text-lg">{skillCategory.category}</h1>
              </div>
              <div className="p-4">
                {skillCategory.list
                  .sort((a, b) => {
                    // Sort by percentage in descending order
                    if (b.percentage !== a.percentage) {
                      return b.percentage - a.percentage;
                    }
                    // If percentages are the same, sort by name in alphabetical order
                    return a.name.localeCompare(b.name);
                  })
                  .map((skill, skillIndex) => (
                    <div
                      key={skillIndex}
                      ref={(el) => (cardsRef.current[itemIndex++] = el)}
                      className="flex items-center gap-2 my-2 fade"
                    >
                      <div className="flex items-center">
                        <skill.icon className="w-5 h-5 mr-4" />
                        <span className="w-24 text-white">{skill.name}</span>
                      </div>
                      <div className="flex-grow h-2 bg-gray-600 rounded-full overflow-hidden">
                        <div
                          className="bg-customBlue h-full transition-all duration-500 ease-out"
                          style={{ width: `${skill.percentage}%` }}
                        />
                      </div>
                      <span className="text-white text-xs ml-2">
                        {skill.percentage}%
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <button
          onClick={handleToggle}
          className="bg-customBlue text-black font-bold py-2 px-4 rounded-md hover:bg-customBlue2 transition-colors duration-300"
        >
          {showAll ? "See Less" : "See More"}
        </button>
      </div>
    </section>
  );
};

export default Skill;
