import AboutSection from "./aboutSection/index";
import IntroSection from "./IntroSection/index";
import EducationSection from "./educationSection/index";
import SkillSection from "./skillSection/index";
import PortfolioSection from "./portfolioSection/index";
import DesignSection from "./designSection/index";
import AwardSection from "./awardSection/index";
import CertSection from "./certSection/index";
import ActivitySection from "./activitySection/index";
import HobbySection from "./hobbySection/index";
import BlogSection from "./blogSection/index";
import ServiceSection from "./serviceSection/index";
import TestimonialSection from "./testimonialSection/index";
import ContactSection from "./contactSection/index";
import InterestSection from "./interestSection/index";
import ComingSoon from "./comingsoon";
import Fab from "@mui/material/Fab";
import { FaArrowUp } from "react-icons/fa";

const GradientWrapper = ({ children }) => {
  const gradientStyle = {
    background: "#081b29",
    minHeight: "100vh",
    padding: "20px",
  };

  return <div style={gradientStyle}>{children}</div>;
};

const HomeSection = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <GradientWrapper>
        <IntroSection />
        <AboutSection />
        <EducationSection />
        <SkillSection />
        <PortfolioSection />
        {/* <DesignSection /> */}
        <AwardSection />
        <CertSection />
        <ActivitySection />
        <HobbySection />
        {/* <BlogSection /> */}
        {/* <ServiceSection />
        <TestimonialSection /> */}
        <ContactSection />
        {/* <InterestSection /> */}
      </GradientWrapper>
      {/* <ComingSoon /> */}
      <Fab
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          zIndex: 1000,
          backgroundColor: "#00abf0",
        }}
        onClick={() => scrollToSection("header")}
      >
        <FaArrowUp className="text-black text-3xl" />
      </Fab>
    </>
  );
};

export default HomeSection;
